<template>
  <b-modal
    id="modal2"
    class="p-4"
    ref="my-modal2"
    centered
    hide-footer
    hide-header
    title-sr-only:false
  >
    <div
      @click="hideModal"
      style="width:10px;height:10px;position:absolute;top:3px;right:10px;cursor:pointer"
    >
      <img src="@/assets/img/krest.svg" alt="" />
    </div>
    <h2 class="PTextB pl-3 pr-3 pt-3">{{ $t("modal2.h2") }}</h2>
    <p class="P1Text mt-3 pl-3 pr-3">{{ $t("modal2.p") }}</p>
    <b-form class="pl-3 pr-3 mb-2" @submit.prevent="usePromo">
      <b-form-group class="mt-3">
        <b-form-input class="input" v-model="promocode"></b-form-input>
      </b-form-group>
    </b-form>
    <div class="d-flex justify-content-center mt-3" v-if="!success">
      {{ $t("modal2.error") }}
    </div>
    <div class="d-flex justify-content-center mt-3">
      <b-row class="w-100 justify-content-center ">
        <b-col md="6" cols="12">
          <b-button
            class="btn btn-primary btn-price-card mb-2 w-100"
            @click="usePromo"
            >{{ $t("modal2.btn") }}</b-button
          >
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { httpClient } from "../HttpClientV1/HttpClient";

export default {
  name: "InputPromocode",
  data() {
    return {
      success: true,
      promocode: null,
    };
  },
  methods: {
    hideModal() {
      this.$refs["my-modal2"].hide();
    },
    usePromo() {
      httpClient
        .get("api/promocode/service/use?promocode=" + this.promocode)
        .then((resp) => {
          if (resp.data.status == "success") {
            localStorage.setItem("promocode", this.promocode.toUpperCase());
            window.location.reload();
          } else {
            this.success = false;
          }
        })
        .catch(() => {
          this.success = false;
        });
    },
  },
};
</script>

<style scoped></style>
