<template>
  <div class="mb-5">
    <b-row
      class="d-flex justify-content-center align-items-start align-content-center animate__animated animate__backInUp mb-5">
      <b-col md="12" lg="5" class="text-left mb-4 mb-lg-0">
        <b-card class="p-4 bg-accent" no-body>
          <b-card-body class="p-0">
            <h3 class="mb-4 bg-accent">{{ $t("confirm_pay.pay_order") }}</h3>
            <b-row class>
              <b-col cols="12">
                <b-row align-v="center" class="mb-3">
                  <b-col cols="2" md="2">
                    <span class="number number_1">1</span>
                  </b-col>
                  <b-col cols="10" md="10" class="pl-0">
                    <p class="h-100 mb-0 ml-2">
                      Укажите название сервиса / операции.
                    </p>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mb-3">
                  <b-col cols="2" md="2">
                    <span class="number number_2">2</span>
                  </b-col>
                  <b-col cols="10" md="10" class="pl-0">
                    <p class="h-100 mb-0 ml-2">
                      Введите стоимость сервиса, который вы хотите оплатить,
                      выберите валюту и&nbsp;способ оплаты.
                    </p>
                  </b-col>
                </b-row>

                <b-row align-v="center" class="mb-3">
                  <b-col cols="2" md="2">
                    <span class="number number_3">3</span>
                  </b-col>
                  <b-col cols="10" md="10" class="pl-0">
                    <p class="mb-0 ml-2">
                      Система рассчитает сумму к&nbsp;оплате с&nbsp;учетом
                      комиссии GCTransfer в&nbsp;зависимости от&nbsp;указанной
                      стоимости.
                    </p>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mb-3">
                  <b-col cols="2" md="2">
                    <span class="number number_3">4</span>
                  </b-col>
                  <b-col cols="10" md="10" class="pl-0">
                    <p class="mb-0 ml-2">
                      Подтвердите сумму и&nbsp;перейдите к&nbsp;оплате.
                    </p>
                  </b-col>
                </b-row>
                <b-row align-v="center" class="mb-3">
                  <b-col cols="2" md="2">
                    <span class="number number_4">5</span>
                  </b-col>
                  <b-col cols="10" md="10" class="pl-0">
                    <p class="h-100 mb-0 ml-2">
                      После оплаты свяжитесь с&nbsp;нами
                      <a href="https://t.me/gctransfer_support_bot" target="_blank"
                        rel="nofollow norefferer">@gctransfer_support_bot</a>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="12" lg="7" class="text-left" v-if="confirm != true">
        <b-card class="p-4" no-body>
          <b-card-body class="p-0">
            <b-form>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label for="input-email" class="mb-0 ">Email</label>
                    <b-input v-model="email" :disabled="disabled_input" placeholder="ivan@gmail.com" type="text"
                      id="input-email" @input="calculate"></b-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group>
                    <label for="input-telegram" class="mb-0">Telegram</label>
                    <b-input v-model="telegram" :disabled="disabled_input" placeholder="Ник в телеграм (@username)"
                      type="text" id="input-telegram" @input="calculate"></b-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group>
                <label for="input-name" class="mb-0">Название сервиса</label>
                <b-input v-model="name_service" :disabled="disabled_input" placeholder="Shopify" type="text"
                  id="input-name" @input="calculate"></b-input>
              </b-form-group>
              <b-form-group>
                <label for="input-price" class="mb-0">Стоимость</label>
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <b-input v-model="price_service" :disabled="disabled_input" placeholder="199" type="number"
                    id="input-price" class @input="calculate"></b-input>
                  <b-form-select v-model="selected_service_currency" :options="['USD', 'EUR', 'RUB']" class="ml-2"
                    @input="calculate"></b-form-select>
                </div>
              </b-form-group>

              <b-form-group class="mt-2">
                <label for="input-type-pay" class="mb-0">Способ оплаты</label>
                <b-form-select v-model="selected" id="input-type-pay" :options="options" @input="calculate">
                </b-form-select>
              </b-form-group>
              <b-row>
                <b-col cols="12" sm="8" md="6" class="pr-sm-0">
                  <p class="mb-0 ms-sm-2">Комиссия сервиса</p>
                </b-col>
                <b-col cols="12" sm="4" md="6" class="text-sm-right pl-sm-0"><span v-if="percent_commission > 0">{{
              percent_commission }} % /</span>
                  ${{ usd_commission }}</b-col>
              </b-row>
              <b-row class="mt-3 mt-sm-1">
                <b-col cols="12" sm="8" md="6">
                  <p class="mb-0 ms-sm-2">Итого</p>
                </b-col>
                <b-col cols="12" sm="4" md="6" class="text-sm-right">
                  $ {{ amount }}</b-col>
              </b-row>
              <b-row class="mt-3 mt-sm-1">
                <b-col cols="12" sm="8" md="6">
                  <p class="mb-0 ms-sm-2">К оплате</p>
                </b-col>
                <b-col cols="12" sm="4" md="6" class="text-sm-right">
                  <span v-if="selected == 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud'">$
                  </span>
                  {{ rub }}
                  <span v-if="selected == 'Qiwi' || selected == 'юMoney'">руб.</span></b-col>
              </b-row>
              <div class="d-flex flex-row justify-content-center mt-2 mb-4 align-items-center" @click="openModal">
                <span class="P1Text me-4 me-sm-0" v-if="promocode == undefined"
                  style="cursor:pointer;text-decoration: underline;">{{ $t("modal2.haveAPromo") }}</span>
                <span class="P1Text me-4 me-sm-0" v-else style="cursor:pointer;text-decoration: none;">
                  <b>{{ $t("modal2.appliedPromo") }}
                    <span style="color:#5598fb">{{
              promocode.toUpperCase()
            }}</span></b>
                </span>
              </div>
              <!-- <label for="input-name" class="mb-0">Итого</label>
              <div
                class="d-flex flex-row justify-content-center mt-0 mb-4 align-items-center"
              >
                <b-input
                  v-model="amount"
                  disabled
                  min="50"
                  type="number"
                  @input="calculate"
                ></b-input>
                <img
                  src="@/assets/img/dollar.png"
                  width="20"
                  style="position: absolute;right: 56%;opacity:1"
                />
                <p class="m-0 ml-1 mr-1">=</p>
                <b-input v-model="rub" disabled></b-input>

                <img
                  src="@/assets/img/rub.svg"
                  width="20"
                  v-if="selected == 'Qiwi' || selected == 'юMoney'"
                  style="position: absolute;right: 10%;opacity:1"
                />
                <img
                  src="@/assets/img/dollar.png"
                  width="20"
                  v-if="selected == 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud'"
                  style="position: absolute;right: 10%;opacity:1"
                />
              </div> -->
              <b-row class="text-center justify-content-center">
                <p v-if="individual">
                  <small>Для расчета индивидуального размера комиссии свяжитесь с
                    <a href="https://t.me/gctransfer_support_bot" target="_blank">
                      поддержкой</a></small>
                </p>
                <p>
                  <small>Итоговая сумма оплаты рассчитывается в $ (USD).</small>
                </p>
                <b-col cols="9">
                  <b-button class="btn btn-primary w-100 btn-price-card mb-2" :disabled="active == 0"
                    @click="nextStepPay">{{ $t("confirm_pay.confirm") }}</b-button>
                </b-col>
              </b-row>
              <b-row v-if="selected == 'Qiwi'">
                <b-col cols="12">
                  <p class="text-left mt-3 pl-2 pr-2">
                    <small>! При оплате через Qiwi максимальная сумма 1 платежа – 15
                      000 RUB. Если сумма вашего платежа с учетом комиссии
                      сервиса больше 15 000 RUB, разделите платеж на несколько
                      частей.</small>
                  </p>
                </b-col>
              </b-row>
              <p style="color:red">{{ error_pay }}</p>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col md="12" lg="7" class="text-left" v-if="confirm & (step != 4)">
        <b-card class="p-4 " no-body>
          <b-card-body class="p-0">
            <b-form>
              <b-row>
                <b-col cols="6">
                  <b-form-group>
                    <label for="input-email" class="mb-0">Email</label>
                    <b-input v-model="email" disabled placeholder="ivan@gmail.com" type="text" id="input-email"
                      @input="calculate"></b-input>
                  </b-form-group>
                </b-col>
                <b-col cols="6">
                  <b-form-group>
                    <label for="input-telegram" class="mb-0">Telegram</label>
                    <b-input v-model="telegram" disabled placeholder="Ник в телеграм (@username)" type="text"
                      id="input-telegram" @input="calculate"></b-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-form-group>
                <label for="input-name" class="mb-0">Название сервиса</label>
                <b-input v-model="name_service" disabled placeholder="Spotify" type="text" id="input-name"
                  @input="calculate"></b-input>
              </b-form-group>
              <b-form-group>
                <label for="input-price" class="mb-0">Стоимость</label>
                <div class="d-flex flex-row justify-content-between align-items-center">
                  <b-input v-model="price_service" disabled placeholder="199" type="number" id="input-price" class
                    @input="calculate"></b-input>
                  <b-form-select v-model="selected_service_currency" :options="['USD', 'EUR']" disabled class="ml-2"
                    @input="calculate"></b-form-select>
                </div>
              </b-form-group>

              <b-form-group class="mt-2">
                <label for="input-type-pay" class="mb-0">Способ оплаты</label>
                <b-form-select v-model="selected" id="input-type-pay" :options="options" disabled @input="calculate">
                </b-form-select>
              </b-form-group>
              <b-row>
                <b-col cols="12" sm="8" md="6" class="pr-sm-0">
                  <p class="mb-0 ms-sm-2">Комиссия сервиса</p>
                </b-col>
                <b-col cols="12" sm="4" md="6" class="text-sm-right pl-sm-0"><span v-if="percent_commission > 0">{{
              percent_commission }} % /</span>
                  ${{ usd_commission }}</b-col>
              </b-row>
              <b-row class="mt-3 mt-sm-1">
                <b-col cols="12" sm="8" md="6">
                  <p class="mb-0 ms-sm-2">Итого</p>
                </b-col>
                <b-col cols="12" sm="4" md="6" class="text-sm-right">
                  $ {{ amount }}</b-col>
              </b-row>
              <b-row class="mt-3 mt-sm-1">
                <b-col cols="12" sm="8" md="6">
                  <p class="mb-0 ms-sm-2">К оплате</p>
                </b-col>
                <b-col cols="12" sm="4" md="6" class="text-sm-right">
                  <span v-if="selected == 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud'">$
                  </span>
                  {{ rub }}
                  <span v-if="selected == 'Qiwi' || selected == 'юMoney'">руб.</span></b-col>
              </b-row>
              <div class="d-flex flex-row justify-content-center mt-2 mb-4 align-items-center" @click="openModal">
                <span class="P1Text me-4 me-sm-0" v-if="promocode == undefined"
                  style="cursor:pointer;text-decoration: underline;display: none;">{{ $t("modal2.haveAPromo") }}</span>
                <span class="P1Text me-4 me-sm-0" v-else style="cursor:pointer;text-decoration: none;">
                  <b>{{ $t("modal2.appliedPromo") }}
                    <span style="color:#5598fb">{{
              promocode.toUpperCase()
            }}</span></b>
                </span>
              </div>
              <!-- <label for="input-name" class="mb-0">Итого</label>
              <div
                class="d-flex flex-row justify-content-center mt-0 mb-4 align-items-center"
              >
                <b-input
                  v-model="amount"
                  disabled
                  min="50"
                  type="number"
                  @input="calculate"
                ></b-input>
                <img
                  src="@/assets/img/dollar.png"
                  width="20"
                  style="position: absolute;right: 56%;opacity:1"
                />
                <p class="m-0 ml-1 mr-1">=</p>
                <b-input v-model="rub" disabled></b-input>

                <img
                  src="@/assets/img/rub.svg"
                  width="20"
                  v-if="selected == 'Qiwi' || selected == 'юMoney'"
                  style="position: absolute;right: 10%;opacity:1"
                />
                <img
                  src="@/assets/img/dollar.png"
                  width="20"
                  v-if="selected == 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud'"
                  style="position: absolute;right: 10%;opacity:1"
                />
              </div> -->
              <b-row class="text-center justify-content-center">
                <b-col cols="9">
                  <a v-if="selected == 'Crypto (BTC/LTC/ETH/USDT) - CryptoCloud'" :href="link" target="_blank"
                    class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t("confirm_pay.pay")
                    }}</a>

                  <a v-else-if="selected == 'Qiwi'" :href="link" target="_blank"
                    class="btn btn-primary btn-price-confirm w-75 mb-2" @click="sendConfirm">{{ $t("confirm_pay.pay")
                    }}</a>

                  <a v-else :href="link" target="_blank" class="btn btn-primary btn-price-confirm w-75 mb-2"
                    @click="sendConfirm">{{ $t("confirm_pay.pay") }}</a>
                </b-col>
              </b-row>
              <p style="color:red">{{ error_pay }}</p>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <InputPromocode></InputPromocode>
  </div>
</template>

<script>
// @ is an alias to /src
import InputPromocode from "../components/InputPromocode.vue";
import { httpClient } from "../HttpClientV1/HttpClient";
export default {
  name: "Home",
  data() {
    return {
      individual: false,
      email: null,
      telegram: null,
      name_service: null,
      price_service: null,
      selected_service_currency: "USD",
      selectedTafir: 6,
      isVisibleCard1: false,
      isVisibleCard2: false,
      isVisibleCard3: false,
      selected: null,
      confirm: false,
      error_pay: null,
      amount: 0,
      address: null,
      rub: 0,
      active: 0,
      step: 2,
      link: null,
      disabled_input: false,
      size: 90,
      interval: null,
      color: "#5598fb",
      amount_pay: null,
      order_id: null,
      percent_commission: 20,
      usd_commission: 0,
      options: [
        { value: null, text: this.$i18n.t("confirm_pay.choose_payment") },
        // {
        //   value: "Ю.Kassa - Visa/MasterCard",
        //   text: "Ю.Kassa - Visa/MasterCard"
        // },
        { value: "Card", text: "Банковская карта РФ" },
        { value: "p2p", text: "СБП" },
        // { value: "Mastercard (комиссия)", text: "Mastercard (комиссия)" },
        //  { value: "юMoney", text: "юMoney" },
        // { value: "Card (Visa/MasterCard) - AliKassa", text: "Card (Visa/MasterCard) - AliKassa" },
        // { value: "Qiwi (Wallet) - AliKassa", text: "Qiwi (Wallet) - AliKassa" },
        // { value: "юMoney (Wallet) - AliKassa", text: "юMoney (Wallet) - AliKassa" },

        {
          value: "Crypto (BTC/LTC/ETH/USDT) - CryptoCloud",
          text: "Crypto (BTC/LTC/ETH/USDT) - CryptoCloud"
        }
        //{ value: "ltc", text: "LTC" },
      ]
    };
  },
  methods: {
    openModal() {
      this.$root.$emit("bv::show::modal", "modal2", "#my-modal2");
    },
    handleVisibility1(isVisibleCard1) {
      this.isVisibleCard1 = isVisibleCard1;
    },
    handleVisibility2(isVisibleCard2) {
      this.isVisibleCard2 = isVisibleCard2;
    },
    handleVisibility3(isVisibleCard3) {
      this.isVisibleCard3 = isVisibleCard3;
    },
    goProfile() {
      this.$router.push("/profile");
    },
    calculate() {
      this.active = 0;
      localStorage.setItem("account", this.selectedTafir);
      if (this.selected != null && this.selectedTafir != null) {
        httpClient
          .get(
            "api/currency/convert/pay?cur=" +
            this.selected +
            "&price_service=" +
            this.price_service +
            "&currency_service=" +
            this.selected_service_currency
          )
          .then(resp => {
            if (resp.data.total >= 5000) {
              this.individual = true;
              this.active = false;
              this.rub = resp.data.total_in_pay;
              this.amount = resp.data.total;
            } else {
              this.individual = false;
              this.rub = resp.data.total_in_pay;
              this.amount = resp.data.total;
              (this.percent_commission = resp.data.percent_commission),
                (this.usd_commission = resp.data.usd_commission);
              if (
                this.name_service != null &&
                this.price_service != null &&
                this.email != null &&
                this.telegram != null
              ) {
                this.active = 1;
              }
            }
          });
      }
    },
    nextStepPay() {
      if (this.selected != null && this.selectedTafir != null) {
        httpClient
          .post("api/confirm/service/pay", {
            type_pay: this.selected,
            amount: this.rub,
            plan_id: this.selectedTafir,
            name_service: this.name_service,
            price_service: this.price_service,
            currency_service: this.selected_service_currency,
            total: this.amount,
            email: this.email,
            telegram: this.telegram
          })
          .then(resp => {
            if (resp.data.status != "error") {
              this.link = resp.data.link;
              try {
                window.open(this.link, "_blank").focus();
              } catch {
                console.error("error");
              }
              (this.address = resp.data.address),
                (this.step = 3),
                localStorage.setItem("order", resp.data.order_id),
                (this.order_id = resp.data.order_id),
                (this.rub = resp.data.ammount_usd),
                (this.loadedQR = true),
                (this.confirm = true);
              this.sendConfirm();
            } else {
              this.error_pay = resp.data.msg;
            }
          });
      } else {
        if (this.selected == null) {
          this.error_pay = this.$i18n.t("confirm_pay.choose_payment");
        } else {
          this.error_pay = this.$i18n.t("confirm_pay.specify_plan");
        }
      }
    },
    sendConfirm() {
      var self = this;
      self.interval = setInterval(function () {
        httpClient
          .get("api/statusorder_confirm?id=" + localStorage.getItem("order"))
          .then(resp => {
            if (resp.data.status == "ok") {
              if (self.step != 4) {
                (self.step = 4),
                  self.confirms(),
                  (self.order_id = localStorage.getItem("order"));
                window.clearInterval(self.interval);
              }
            }
          });
      }, 2000);
    },
    getInfoPromocode() {
      if (this.promocode != undefined) {
        httpClient
          .get("api/promocode/service/getInfo?promocode=" + this.promocode)
          .then(response => {
            if (response.data.status == "success") {
              this.percent = response.data.percent;
              this.calculate();
            }
          })
          .catch(() => {
            this.percent = null;
          });
      } else {
        this.percent = null;
      }
    }
  },
  mounted() {
    this.selectedTafir = 6;
    this.calculate();
    this.getInfoPromocode();
  },
  computed: {
    promocode() {
      return localStorage.getItem("promocode");
    }
  },
  components: { InputPromocode }
};
</script>
