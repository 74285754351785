<template>
  <b-container fluid class="empty">
    <div class="mb-4">
      <!-- As a link -->
      <b-row
        class="align-items-center align-content-center justify-content-center text-center mt-4"
      >
        <b-col cols="12" class="text-center">
          <b-img-lazy
            center
            v-bind="mainProps"
            :src="loadLogo"
            class="mx-auto"
          ></b-img-lazy>
        </b-col>
      </b-row>
    </div>
    <b-container style="min-height:70vh">
      <router-view />
    </b-container>
    <b-container class="mb-5 mt-5">
      <b-row
        class="d-flex justify-content-center align-items-center align-content-center pl-3 pr-3 animate__animated animate__backInUp"
      >
        <a
          href="https://gctransfer.co/user-agreement"
          target="_blank"
          class="pr-3 nav-link"
          >Пользовательское соглашение</a
        >
        <a
          href="https://gctransfer.co/payments"
          target="_blank"
          class="pr-3 nav-link"
          >Условия оплаты</a
        >
        <a href="https://gctransfer.co/" target="_blank" class="pr-3 nav-link"
          >gctransfer.co</a
        >
        <a
          href="https://t.me/gctransfer_support_bot"
          target="_blank"
          class="nav-link"
        >
          <img
            src="@/assets/img/telegram.svg"
            width="20"
            style="position: relative;top: -1px;"
          />
          support</a
        >
      </b-row>
    </b-container>
  </b-container>
</template>
<script>
export default {
  data() {
    return {
      mainProps: {
        center: false,
        fluidGrow: false,
        blank: true,
        blankColor: "#f0f7fd",
        width: 200,
        height: 70,
        class: "my-0",
      },
    };
  },
  computed: {
    loadLogo() {
      return process.env.VUE_APP_API_SERVER + "static/img/logoGC.png";
    },
  },
};
</script>
