import ConfirmPayService from "../views/ConfirmPayService.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: ConfirmPayService,
    meta: { layout: "main", requiresAuth: false },
  },
];

export default routes;
