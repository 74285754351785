import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locale",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}
const dateTimeFormats = {
  en: {
    short: {
      year: "numeric",
      month: "short",
      day: "numeric",
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
    },
  },
};
const numberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
  },
  eu: {
    currency: {
      style: "currency",
      currency: "EUR",
    },
  },
};
const i18n = new VueI18n({
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
  messages: loadLocaleMessages(),
  numberFormats,
  dateTimeFormats,
});
var locale = localStorage.getItem("locale");
if (locale == null) {
  let locale_browser =
    navigator.language.split("-")[0] || navigator.userLanguage.split("-")[0];
  localStorage.setItem("locale", "ru");
}
i18n.locale = localStorage.getItem("locale");
export default i18n;
